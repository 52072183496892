<template>
  <router-view/>
</template>

<style>

:root{
  --green-blnv: #004d27;
  --white-blnv: #fff;
  --weight-tittle-blnv: 500;
  --size-tittle-blnv: 1rem;
}

#app {
   /* font-family: Verdana, Geneva, Tahoma, sans-serif;  */
  /* font-family: 'Roboto', sans-serif; */
  font-family: "Homemade Apple", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 16px;
}

body{
  font-weight: 1rem;
  padding-top: 5rem;
}

.tittle-bln{
  color: var(--green-blnv);
  font-family: "Rubik", Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: var(--weight-tittle-blnv);
}

.tittle-bln-xl{
  color: var(--green-blnv);
  font-family: "Rubik", Helvetica, Arial, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: var(--weight-tittle-blnv);
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
