<template>
  <div>
    <div class="inicio">
      <NavbarMain />
      <Slider />

      <!--
    <div class="container">
      <div class="titulo">
        <p>Novedades</p>
      </div>
-->
      <!-- style="zoom: 80%" -->
      <div class="container">
        <div class="form-row mt-4">
          <h3 class="tittle-bln">
            ¿A dónde quieres viajar? Conoce nuestras rutas y oferta del
            servicio:
          </h3>
        </div>

        <div v-show="showProgressRutas">
          <ProgressBar mode="indeterminate" style="height: 0.5em" />
        </div>

        <div class="grid formorigendestino">
          <div class="col">
            <div class="col-12 md:col-4">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">Elige un Origen: </span>
                <Dropdown
                  v-model="rutaorigen"
                  :options="origenes"
                  @change="consultarDestinos()"
                  optionLabel="detalle"
                  placeholder="Seleccionar Origen"
                />
              </div>
            </div>
          </div>

          <div class="col">
            <div class="col-12 md:col-4">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon"> Destino </span>
                <Dropdown
                  v-model="rutadestino"
                  :options="origenes"
                  @change="consultarOrigenesyDestinos()"
                  optionLabel="detalle"
                  placeholder="Seleccionar Destino"
                />
              </div>
            </div>
          </div>
        </div>

        <!--
        <div class="form-row">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead style="background-color: #004d27; color: white">
                <tr>
                  <th scope="col">ORIGEN</th>
                  <th scope="col">DESTINO</th>
                  <th scope="col">TARIFA</th>
                   <th scope="col">TARIFA IDA/REGRESO</th> 
                  <th scope="col">HORA/FRECUENCIA</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="ruta in rutas" :key="ruta.id">
                  <td>{{ ruta.origen }}</td>
                  <td>{{ ruta.destino }}</td>
                  <td>{{ ruta.tarifa }}</td>
                  <td>{{ ruta.tarifapromo }}</td> 
                  <td>{{ ruta.hora }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> -->
      </div>

      <div class="contenedor-rutas" v-show="!showProgressRutas">
        <div
          v-for="ruta in rutas"
          :key="ruta.id"
          class="cont-ruta"
          :style="{
            'background-image':
              'url(' + require(`@/assets/${ruta.rdis_img_destino}`) + ')',
          }"
        >
          <div class="cont-ruta-opaque"></div>

          <div class="cont-ruta-info">
            <div class="info-nombre">
              <p class="info-tittle">{{ ruta.destino }}</p>
              <p class="info-details">Desde {{ ruta.origen }}, COL</p>
            </div>
            <div class="info-precio">
              <p>COP - {{ ruta.tarifa }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="contenedor-rutas" v-show="showProgressRutas">
        <Skeleton height="18rem" v-show="showProgressRutas"></Skeleton>
        <Skeleton height="18rem" v-show="showProgressRutas"></Skeleton>
        <Skeleton height="18rem" v-show="showProgressRutas"></Skeleton>
        <Skeleton height="18rem" v-show="showProgressRutas"></Skeleton>
        <Skeleton height="18rem" v-show="showProgressRutas"></Skeleton>
        <Skeleton height="18rem" v-show="showProgressRutas"></Skeleton>
      </div>

      <div class="footer-inicio">
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import NavbarMain from "@/components/NavbarMain.vue";
import Slider from "@/components/Slider.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Inicio",

  data() {
    return {
      showProgressRutas: false,
      origenes: [],
      rutas: [],
      destinos: [],
      rutaorigen: { id: 1, detalle: "SANTA MARTA" },
      rutadestino: null,
    };
  },

  components: {
    NavbarMain,
    Slider,
    Footer,
  },

  created: function () {
    this.getOrigenes();
    this.consultarDestinos();
  },

  computed: {
    //<!-- :src="require(`@/assets/${publicacion.imagen}`)" -->
  },

  methods: {
    getOrigenes: async function () {
      this.showProgressRutas = true;
      let vue = this;
      await this.axios
        .get("model/rutasdisponibles.php?dato=getciudades")
        .then(function (response) {
          vue.origenes = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function () {
          vue.showProgressRutas = false;
        });
    },

    consultarDestinos: async function () {
      this.showProgressRutas = true;
      console.log(this.rutaorigen);
      let vue = this;
      await this.axios
        .get(
          "model/rutasdisponibles.php?dato=getrutasxorigen&ruta_origen=" +
            this.rutaorigen.id
        )
        .then(function (response) {
          vue.rutas = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function () {
          vue.showProgressRutas = false;
        });
    },

    consultarOrigenesyDestinos: async function () {
      this.showProgressRutas = true;
      let vue = this;
      await this.axios
        .get(
          "model/rutasdisponibles.php?dato=getrutasxorigenydestino&ruta_origen=" +
            this.rutaorigen.id +
            "&ruta_destino=" +
            this.rutadestino.id
        )
        .then(function (response) {
          vue.rutas = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function () {
          vue.showProgressRutas = false;
        });
    },
  },
};
</script>

<style scope>
.inicio {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
  padding-bottom: 266px;
}

.contenedor-rutas {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 85%;
  margin: auto;
}

.cont-ruta {
  height: 18rem;
  border-radius: 10px;
  background-size: cover;
  background-position: center center;

  display: flex;
  flex-direction: column-reverse;
  /* filter: brightness(70%); */
}

.cont-ruta:hover {
  scale: 1.06;
  opacity: 0.6;
}

.cont-ruta-opaque {
  height: 5rem;
  background-color: rgba(0, 0, 0, 0.7);
}

.cont-ruta-info {
  height: 5rem;
  border-radius: 20px;
  position: absolute;
  display: flex;
  margin-left: 0.5rem;
  gap: 2rem;
}

.info-nombre {
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: start;
  /* justify-content: start; */
  /* border: green solid 4px; */
  margin: 0;
}

.info-nombre > .info-tittle {
  /* flex-grow: 3; */
  /* flex: auto; */
  font-family: "Comic Neue", cursive;
  font-weight: bold;
  font-size: 1.5rem;
  color: white;
  margin: 0;

  /* display: flex;
  justify-content: start;
  align-items: end; */
}

.info-nombre > .info-details {
  font-family: "Comic Neue", cursive;
  font-weight: bold;
  font-size: 0.8rem;
  color: white;
  margin: 0;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0; */
}

.info-precio {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  margin: 0;
}

.info-precio > p {
  font-family: "Comic Neue", cursive;
  font-weight: bold;
  font-size: 1.3rem;
  color: white;
  margin: 0;
}

@media screen and (max-width: 992px) {
  .contenedor-rutas {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 85%;
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .contenedor-rutas {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    width: 85%;
    margin: auto;
  }

  .tittle-bln {
    font-size: var(--size-tittle-blnv);
  }
}

.footer-inicio {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.formorigendestino {
  margin-top: 10px;
}

.btn-berlinave {
  background-color: #004d27 !important;
  color: white !important;
}
</style>
